<template>
    <div>
        <ShStepTwo
            kind="coupon"
            :kindtext="$lang.pages.compartirCuponCrear.at_coupon"
            :info="options"
            :validation-status="validationStatus"
            :loading="loading"
            @makePost="makePost"
            @setValidations="setValidations"
        />
    </div>
</template>

<script lang="ts" setup>
import { format } from 'date-fns'
import encodeUrl from 'encodeurl'
import { useAuthStore } from '~/store/auth'
import { useRootStore } from '~/store/root'

import { parseImages } from '~/util/image-functions'
import type { Api } from '~~/global'
const { buildHeaders, baseURL, endpoints } = useApiConfig()
const props = defineProps({
    options: {
        type: Object,
        default() {
            return {
                url: '',
                code: '',
            }
        },
    },
})

definePageMeta({ middleware: ['auth-required'] })

const loading = ref(false)

const validationStatus = reactive({
    url: false as boolean | string,
    title: false as boolean | string,
    description: false as boolean | string,
    discount_value: false as boolean | string,
    category_id: false as boolean | string,
    country_id: false as boolean | string,
})

const Route = useRoute()
const {
    public: { origin },
} = useRuntimeConfig()
const { $lang } = useNuxtApp()

const title = $lang.pages.compartirCuponCrear.title_seo
const description = $lang.pages.compartirCuponCrear.description_seo

useSeoMeta({
    title: title,
    ogUrl: origin + Route.path,
    ogTitle: title,
    description: description,
    ogDescription: description,
    ogImageAlt: title,
    twitterCard: 'summary_large_image',
})

useHead({
    link: [
        {
            rel: 'canonical',
            href: origin + Route.path,
        },
    ],
})

const scrollToComponent = useScrollToComponent()
const RootStore = useRootStore()
const Router = useRouter()

const AuthStore = useAuthStore()

const setValidations = (
    name: 'url' | 'title' | 'description' | 'discount_value' | 'category_id' | 'country_id',
    status: boolean | string = false,
) => {
    validationStatus[name] = status
}

const makePost = async (data: {
    imageActive: 0
    imagesUploaded: Array<{ dataUrl?: any; file?: File }>
    url: ''
    offer_details: {
        price_offer: ''
        regular_price: ''
        ship_costs: ''
        freeship: boolean
    }
    discount: {
        type: ''
        value: ''
    }
    code: ''
    title: ''
    description: ''
    categoryId: number
    countryId: number
    forumCategoryId: number
    datePicker: {
        start: ''
        end: ''
    }
    isLocal: boolean
    messages: number
    type: 'see-coupon'
}) => {
    console.log(data)

    loading.value = true

    const { images, featured } = await parseImages({
        imageActive: data.imageActive,
        title: data.title,
        imagesUploaded: data.imagesUploaded,
    })

    let StartAt = ''
    let EndAt = ''

    if (data.datePicker.start) {
        StartAt = format(data.datePicker.start, "yyyy-MM-dd'T'HH:mm:ss")
    }

    if (data.datePicker.end) {
        EndAt = format(data.datePicker.end, "yyyy-MM-dd'T'HH:mm:ss")
    }

    const bodyFormData = new FormData()

    if (featured) bodyFormData.append('featured', featured)

    for (let i = 0; i < images.length; i++) {
        bodyFormData.append('images[]', images[i])
    }

    if (data.url) bodyFormData.append('url', data.url)
    if (data.discount.type) bodyFormData.append('discount_type', data.discount.type)
    if (data.discount.value) bodyFormData.append('discount_value', data.discount.value)
    if (data.code) bodyFormData.append('code', data.code)
    if (data.title) bodyFormData.append('title', data.title)
    if (data.description) bodyFormData.append('description', data.description)
    if (data.categoryId) bodyFormData.append('category_id', String(data.categoryId))
    if (StartAt) bodyFormData.append('start_at', StartAt)
    if (EndAt) bodyFormData.append('expired_at', EndAt)
    if (data.countryId) bodyFormData.append('country_id', String(data.countryId))
    if (data.isLocal) bodyFormData.append('is_local', data.isLocal ? '1' : '0')

    const response = await $fetch<Api.Responses.Actions.SuccessCoupon | Api.Responses.Actions.FailedCoupon>(
        endpoints.create.coupons.path,
        {
            method: 'POST',
            body: bodyFormData,
            headers: buildHeaders(AuthStore.SessionToken),
            baseURL,
        },
    ).catch((e) => e.data)

    if (!response) {
        RootStore.setSiteNotification({
            duration: 5000,
            text: $lang.pages.compartirCuponCrear.error_on_create,
            type: 'error',
        })
    } else if (response.feedback === 'params_validation_failed') {
        validationStatus.url = response.data.url?.[0] || false
        validationStatus.title = response.data.title?.[0] || false
        validationStatus.description = response.data.description?.[0] || false
        validationStatus.discount_value = response.data.discount_value?.[0] || false
        validationStatus.category_id = response.data.category_id?.[0] || false
        validationStatus.country_id = response.data.country_id?.[0] || false
    } else if (response.feedback === 'resource_created') {
        RootStore.setSiteNotification({
            duration: 5000,
            text: $lang.pages.compartirCuponCrear.coupon_created,
            type: 'success',
        })
        Router.push({
            path: `/compartir/finalizado`,
            query: {
                variant: 'coupon',
                slug: response.data.coupon.slug,
                revision: 'false',
                title: encodeUrl(response.data.coupon.title),
            },
        })
    } else if (response.feedback === 'resource_needs_approval') {
        Router.push({
            path: `/compartir/finalizado`,
            query: {
                variant: 'coupon',
                revision: 'true',
                title: encodeUrl(response.data.coupon.title),
            },
        })
    } else {
    }
    loading.value = false
}
</script>
